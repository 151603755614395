import { isBrowser } from './helpers.js';
import { getTransformedPreviewBranch, isLocal, isPreview } from './metadata.js';

/**
 * Get the site URL.
 * @returns {string}
 */
export const getUrl = () => {
  if (isBrowser()) {
    return window.location.origin;
  }

  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL || // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL || // Automatically set by Vercel.
    'http://localhost:3000/';

  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`;

  // Make sure to include a trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;

  return url;
};

/**
 * Get the site host.
 * @param {string | undefined} url
 * @returns {string}
 */
export const getHost = (url = undefined) => {
  url = url ?? getUrl();
  try {
    return new URL(url).host;
  } catch (err) {
    console.error(err);
    throw new Error(`Invalid URL: ${url}`);
  }
};

/**
 * Get the URL for the student app
 * @returns {string}
 */
export const getStudentUrl = () => {
  if (isLocal()) {
    return 'http://student.localhost:3000/';
  }

  if (isPreview()) {
    const branch = getTransformedPreviewBranch();
    return `https://student-${branch}-magicschoolai.vercel.app/`;
  }

  return 'https://student.magicschool.ai/';
};

/**
 * Get the URL for the teacher app
 * @returns {string}
 */
export const getTeacherUrl = () => {
  if (isLocal()) {
    return 'http://localhost:3000/';
  }

  if (isPreview()) {
    const branch = getTransformedPreviewBranch();
    return `https://teacher-${branch}-magicschoolai.vercel.app/`;
  }

  return 'https://app.magicschool.ai/';
};

/**
 * Is the URL a student URL?
 * @param {string} url
 * @returns {boolean}
 */
export const isStudentUrl = (url) => {
  const host = getHost(url);
  return isStudentHost(host);
};

/**
 * Is the URL a teacher URL?
 * @param {string} url
 * @returns {boolean}
 */
export const isTeacherUrl = (url) => {
  const host = getHost(url);
  return isTeacherHost(host);
};

/**
 * Is the host a student host?
 * @param {string} host
 */
export const isStudentHost = (host) => {
  // Local: student.localhost:3000 or localhost:3000
  if (isLocal()) {
    return host.startsWith('student.');
  }

  // Preview: student-<branch>-magicschoolai.vercel.app
  if (isPreview()) {
    return host.startsWith('student-');
  }

  return host.startsWith('student.');
};

/**
 * Is the host a teacher host?
 * @param {string} host
 */
export const isTeacherHost = (host) => {
  // Local: localhost:3000
  if (isLocal()) {
    return !host.startsWith('student.') && host === 'localhost:3000';
  }

  // Preview: teacher-<branch>-magicschoolai.vercel.app or *-magicschoolai.vercel.app
  if (isPreview()) {
    return host.startsWith('teacher-') || !host.startsWith('student-');
  }

  // Production: app.magicschool.ai
  return !host.startsWith('student.');
};
