globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"webapp@a69f56718dc2dff581921354872f7fff50363621"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { isProduction } from '@magicschool/utils/nextjs/metadata';
import { getStudentUrl, getTeacherUrl } from '@magicschool/utils/nextjs/url';
import * as Sentry from '@sentry/nextjs';

const teacherHost = new URL(getTeacherUrl()).hostname;
const studentHost = new URL(getStudentUrl()).hostname;

Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,

  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  ignoreErrors: [
    /**
     * @link https://github.com/WICG/ResizeObserver/issues/38#issuecomment-422126006,
     * @link https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
     */
    'ResizeObserver loop limit exceeded',
    'InvalidStateError: Cannot close a closed AudioContext.',

    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'TypeError: Failed to fetch', // supabase failing
    'TypeError: Load failed', // seems to be a safari specific error type for the same as above
    "Can't find variable: webpackJsonp", // two odd users in UAE
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.", // we can't seem to track this down, it seems like a next.js thing as we're not explicitly calling removeChild
    /^NotAllowedError:.*/, // user denied permission to use the microphone or something
    'NotFoundError: Requested device not found', // user doesn't have a microphone
    'ReferenceError: structuredClone is not defined',
    "Can't find variable: structuredClone",
    "Cannot read properties of null (reading 'removeChild')", // This occurs in NextJS bootstrapped code that we can't control but also doesn't
    "Cannot read properties of null (reading 'ownerDocument')", // Same situation as above
    "Cannot read properties of undefined (reading 'parentElement')", // Same situation as above
    "undefined is not an object (evaluating 't.parentElement')", // Same situation as above
    'Node.removeChild: The node to be removed is not a child of this node',
    "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.", // Same situation as above
    "Can't close an AudioContext twice", // This occurs during STT but doesn't actually break anything
    "Unexpected token '<'",
    /Can't find variable: GM_.*/, // GreaseMonkey error that we don't care about
    /^jb$/, // Some random error that we can't track down
  ],

  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /api-iam\.intercom\.io/i,
    // Adobe SDK
    /adobe/i,
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: ['false', '0'].includes(process.env.NEXT_BUILD_ENV_SENTRY_TRACING || '') ? undefined : !isProduction() ? 1.0 : 0.1,
  replaysSessionSampleRate: ['false', '0'].includes(process.env.NEXT_BUILD_ENV_SENTRY_TRACING || '') ? undefined : 0.05,
  replaysOnErrorSampleRate: ['false', '0'].includes(process.env.NEXT_BUILD_ENV_SENTRY_TRACING || '') ? undefined : 1.0,
  tracePropagationTargets: [teacherHost, studentHost, /^\//],
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['magicschoolai'],
      behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
    }),
  ],
});
