/**
 * Are we turboing?
 * @returns {boolean}
 */
export const isTurbo = () => {
  const isTurbo = process.env.npm_lifecycle_event?.includes('turbo');
  return isTurbo;
};

/**
 * Is the environment production?
 * @returns {boolean}
 */
export const isProduction = () => {
  const env = process?.env?.NEXT_PUBLIC_VERCEL_ENV || process?.env?.NODE_ENV;
  return env === 'production';
};

/**
 * Is the environment preview?
 * @returns {boolean}
 */
export const isPreview = () => {
  return process?.env?.NEXT_PUBLIC_VERCEL_ENV === 'preview';
};

/**
 * Is the environment local?
 * @returns {boolean}
 */
export const isLocal = () => {
  return !process?.env?.NEXT_PUBLIC_VERCEL_ENV || process?.env?.NEXT_PUBLIC_VERCEL_ENV === 'local';
};

/**
 * Get the build prefix.
 * @returns {string}
 */
export const getBuildPrefix = () => {
  const BUILD_SHA = getBuildSHA();
  return BUILD_SHA?.substring(0, Math.min(BUILD_SHA.length, 7));
};

/**
 * Get the build SHA.
 * @returns {string}
 */
export const getBuildSHA = () => {
  return process?.env?.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'local';
};

/**
 * Get the preview's git branch.
 * @returns {string | undefined}
 */
export const getPreviewBranch = () => {
  return process?.env?.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
};

/**
 * Get the transformed preview branch.
 * This replaces '/' with '-', removes '_', converts to lowercase, and trims to 41 characters.
 * This is to follow the Vercel's preview URL limitations.
 * @returns {string}
 */
export const getTransformedPreviewBranch = () => {
  const previewBranch = getPreviewBranch();
  if (!previewBranch) {
    throw new Error('Preview branch is not set');
  }

  return previewBranch
    .replace(/\//g, '-') // Replace '/' with '-'
    .replace(/_/g, '') // Remove '_'
    .toLowerCase() // Convert to lowercase
    .substring(0, 41); // Trim to first 41 characters
};
